import { connect, RenderItemFormSidebarCtx, RenderItemFormSidebarPanelCtx } from "datocms-plugin-sdk";
import "datocms-react-ui/styles.css";
import ConfigScreen from "./entrypoints/ConfigScreen";
import { render } from "./utils/render";
import Page from "./entrypoints/Page";
import { normalizeParameters, Parameters } from "./types";
import SidebarPanel from "./entrypoints/SidebarPanel";
import SidebarFrame from "./entrypoints/SidebarFrame";
import { readSidebarWidth } from "./utils/persistedWidth";

connect({
  renderConfigScreen(ctx) {
    return render(<ConfigScreen ctx={ctx} />);
  },
  itemFormSidebarPanels(_itemType, ctx) {
    const { startOpen, productModelId } = normalizeParameters(ctx.plugin.attributes.parameters as Parameters);
    if (_itemType.id !== productModelId) {
      return [];
    }
    return [
      {
        id: "product-exporter",
        label: "Product Exporter",
        startOpen,
        placement: ["after", "info"],
      },
    ];
  },
  renderItemFormSidebarPanel(
    _sidebarPanelId,
    ctx: RenderItemFormSidebarPanelCtx
  ) {
    render(<SidebarPanel ctx={ctx} />);
  },
  itemFormSidebars(_itemType, ctx) {
    const { defaultSidebarWidth } = normalizeParameters(ctx.plugin.attributes.parameters as Parameters);

    return [
      {
        id: "webPreviews",
        label: "Side-by-side web previews",
        preferredWidth: readSidebarWidth(ctx.site) || defaultSidebarWidth,
      },
    ];
  },
  contentAreaSidebarItems(ctx) {
    return [
      {
        label: "Product Exporter",
        icon: "file-export",
        placement: ["before", "menuItems"],
        pointsTo: {
          pageId: "product-exporter",
        },
      },
    ];
  },
  renderPage(pageId, ctx) {
    return render(<Page ctx={ctx} />);
  },
});
