import { RenderItemFormSidebarPanelCtx } from "datocms-plugin-sdk";
import { Button, Canvas } from "datocms-react-ui";
import styles from "./styles.module.css";
import { normalizeParameters } from "../../types";

type PropTypes = {
  ctx: RenderItemFormSidebarPanelCtx;
};

const PreviewUrl = ({ ctx }: PropTypes) => {
  // const { productExportUrl } = normalizeParameters(
  //   ctx.plugin.attributes.parameters
  // );
  console.log(ctx);
  const onSubmit = async (e: any) => {
    e.preventDefault();
    ctx.notice("Export job started!");
    // TODO: Implement export job
    // fetch(productExportUrl, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     email,
    //     fileTypes,
    //     selectedProductIds,
    //   }),
    // })
    //   .then((response) => {
    //     // check if the response is ok and type of response is json
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     if (response.headers.get("Content-Type") === "application/json") {
    //       return response.json();
    //     }
    //     return response;
    //   })
    //   .then((data) => {
    //     console.log("Success:", data);
    //     ctx.notice("Export job started!");
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //     ctx.alert("Error starting export job!");
    //   });
  };

  return (
    <Canvas ctx={ctx}>
      <div className={styles.button}>
        <Button buttonSize="m" buttonType="muted" onClick={onSubmit}>
          Download CSV
        </Button>
      </div>
      <div className={styles.button}>
        <Button buttonSize="m" buttonType="primary" onClick={onSubmit}>
          Download PDF
        </Button>
      </div>
    </Canvas>
  );
};

export default PreviewUrl;
