import { RenderPagePropertiesAndMethods } from "datocms-plugin-sdk";
import styles from "./styles.module.css";
import { Canvas } from "datocms-react-ui";
import { ProductExporterForm } from "../../components/ProductExporterForm/ProductExporterForm";
import { normalizeParameters, Parameters } from "../../types";

type PropTypes = {
  ctx: RenderPagePropertiesAndMethods;
};

export default function Page({ ctx }: PropTypes) {
  const { productExportUrl } = normalizeParameters(
    ctx.plugin.attributes.parameters as Parameters
  );
  return (
    <Canvas ctx={ctx}>
      <div className={styles.layout}>
        <div className={styles.header}>
          <h2 className={styles.heading}>RND CMS Product Exporter</h2>
          <p className={styles.pageDescription}>
            Select products and export them as PDF and / or PDF files.
          </p>
          <p>{productExportUrl}</p>
        </div>
        <div className={styles.mainContainer}>
          <ProductExporterForm ctx={ctx} />
        </div>
      </div>
    </Canvas>
  );
}
