import { useState } from "react";
import {
  Form,
  SelectField,
  FieldGroup,
  TextField,
  Button,
} from "datocms-react-ui";
import styles from "./ProductExporterForm.module.css";
import type { Item, RenderPagePropertiesAndMethods } from "datocms-plugin-sdk";
import { normalizeParameters } from "../../types";

type PropTypes = {
  ctx: RenderPagePropertiesAndMethods;
};

export function ProductExporterForm({ ctx }: PropTypes) {
  const [email, setEmail] = useState<string>("");
  const [fileTypes, setFileTypes] = useState<any>(null);
  const [selectedProducts, setSelectedProducts] = useState<Item[]>([]);
  const { productExportUrl, productModelId } = normalizeParameters(
    ctx.plugin.attributes.parameters
  );
  const selectedProductIds = selectedProducts.map((p) => p.id);
  const selectedProductsCount = selectedProducts.length;

  const onSelectProducts = async (e: any) => {
    e.preventDefault();
    if (!productModelId) {
      console.error("Product model ID not set!");
      ctx.alert("Product model ID not set!");
      return;
    }
    const items = await ctx.selectItem(productModelId, { multiple: true });
    if (items) {
      // push new items to the selectedProducts array
      setSelectedProducts((prevState) => {
        const existingIds = new Set(prevState.map((p) => p.id));
        const newItems = items.filter((item) => !existingIds.has(item.id));
        return [...prevState, ...newItems];
      });
      ctx.notice(`Added products!`);
    } else {
      // if the user closes the modal without selecting any items
    }
  };

  const onClearProducts = (e: any) => {
    e.preventDefault();
    setSelectedProducts([]);
    ctx.notice("Products cleared!");
  };

  const onSubmit = (e: any) => {
    if (!productExportUrl) {
      console.error("Product export URL not set!");
      ctx.alert("Product export URL not set!");
      return;
    }
    e.preventDefault();
    console.log("onSubmit: ", {
      email,
      fileTypes,
      selectedProductIds,
    });
    // Send post request using fetch
    fetch(productExportUrl, {
      method: "POST",
      body: JSON.stringify({
        email,
        fileTypes,
        selectedProductIds,
      }),
    })
      .then((response) => {
        // check if the response is ok and type of response is json
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        if (response.headers.get("Content-Type") === "application/json") {
          return response.json();
        }
        return response;
      })
      .then((data) => {
        console.log("Success:", data);
        ctx.notice("Export job started!");
      })
      .catch((error) => {
        console.error("Error:", error);
        ctx.alert("Error starting export job!");
      });
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <FieldGroup className={styles["fieldGroup"]}>
          <TextField
            name="email"
            id="email"
            label="Where should we email the files?"
            value={email}
            required
            placeholder="Enter email address"
            textInputProps={{
              type: "email",
            }}
            onChange={setEmail}
          />

          <SelectField
            name="multipleOption"
            id="multipleOption"
            label="Select the file types you want to export:"
            value={fileTypes}
            selectInputProps={{
              isMulti: true,
              options: [
                { label: "PDF", value: "pdf" },
                { label: "Excel", value: "excel" },
              ],
            }}
            onChange={setFileTypes}
          />
        </FieldGroup>
        <FieldGroup>
          <div className={styles.productSelector}>
            <div className={styles.productSelectorTop}>
              <Button
                type="button"
                buttonSize="m"
                buttonType="primary"
                onClick={onSelectProducts}
              >
                Add products
              </Button>
              <p>{selectedProductsCount} products selected</p>

              <Button
                type="button"
                buttonSize="m"
                buttonType="muted"
                onClick={onClearProducts}
              >
                Clear
              </Button>
            </div>
            <div className={styles.productList}>
              {selectedProducts.map((product) => (
                <div key={product.id} className={styles.product}>
                  <p>{product.attributes.sku as string}</p>
                  <p className={styles.productName}>
                    {product.attributes.display_name as string}
                  </p>
                  <Button
                    type="button"
                    buttonSize="xxs"
                    buttonType="negative"
                    onClick={() =>
                      setSelectedProducts((prevState) =>
                        prevState.filter((p) => p.id !== product.id)
                      )
                    }
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </FieldGroup>
        <FieldGroup>
          <Button
            buttonSize="m"
            buttonType="primary"
            onClick={onSubmit}
            disabled={selectedProductIds.length === 0}
          >
            Start Export Job
          </Button>
        </FieldGroup>
      </Form>
    </>
  );
}
